export const header_sandi = {
    "SMID": {"value": "1", "desc": "SINOP"},
    "SIID": {"value": "1", "desc": "SINOP"},
    "SNID": {"value": "1", "desc": "SINOP"},
    "SMVE": {"value": "56", "desc": "SHIP FM 13"},
    "SIVE": {"value": "56", "desc": "SHIP FM 13"},
    "SNVE": {"value": "56", "desc": "SHIP FM 13"},
    "SAID": {"value": "4", "desc": "METAR"},
    "SPID": {"value": "5", "desc": "SPECI"},
    "MMID": {"value": "8", "desc": "WXREV"},
    "USID": {"value": "3", "desc": "TEMP"},
    "UKID": {"value": "3", "desc": "TEMP"},
    "ULID": {"value": "3", "desc": "TEMP"},
    "UEID": {"value": "3", "desc": "TEMP"},
    "UPID": {"value": "2", "desc": "PILOT"},
    "UGID": {"value": "2", "desc": "PILOT"},
    "UIID": {"value": "2", "desc": "PILOT"},
    "UHID": {"value": "2", "desc": "PILOT"},
    "UQID": {"value": "2", "desc": "PILOT"},
    "CSID": {"value": "7", "desc": "CLIMAT"},
    "SEID": {"value": "10", "desc": "SEISMIC"},
    "FAID": {"value": "15", "desc": "Aviation area/GAMET/advisories FM 53 (ARFOR)"},
    "FBID": {"value": "16", "desc": "Upper winds and temperatures FM 50 (WINTEM)"},
    "FCID": {"value": "17", "desc": "Aerodrome (VT < 12 hours) FM 51 (TAF)"},
    "FDID": {"value": "18", "desc": "Radiological trajectory dose FM 57 (RADOF)"},
    "FEID": {"value": "19", "desc": "Extended [TEXT]"},
    "FFID": {"value": "20", "desc": "Shipping FM 46 (IAC FLEET)"},
    "FGID": {"value": "21", "desc": "Hydrological FM 68 (HYFOR)"},
    "FHID": {"value": "22", "desc": "Upper-air thickness [TEXT]"},
    "FIID": {"value": "23", "desc": "Iceberg [TEXT]"},
    "FJID": {"value": "24", "desc": "Radio warning service (including IUWDS data) [TEXT]"},
    "FKID": {"value": "25", "desc": "Tropical cyclone advisories [TEXT]"},
    "FLID": {"value": "26", "desc": "Local/area [TEXT]"},
    "FMID": {"value": "27", "desc": "Temperature extremes [TEXT]"},
    "FNID": {"value": "28", "desc": "Space weather advisories [TEXT]"},
    "FOID": {"value": "29", "desc": "Guidance [TEXT]"},
    "FPID": {"value": "30", "desc": "Public [TEXT]"},
    "FQID": {"value": "31", "desc": "Other shipping [TEXT]"},
    "FRID": {"value": "32", "desc": "Aviation route FM 54 (ROFOR)"},
    "FSID": {"value": "33", "desc": "Surface FM 45 (IAC)/FM 46 (IAC FLEET)"},
    "FTID": {"value": "6", "desc": "Aerodrome (VT ≥ 12 hours) FM 51 (TAF)"},
    "FUID": {"value": "34", "desc": "Upper air FM 45 (IAC)"},
    "FVID": {"value": "35", "desc": "Volcanic ash advisories [TEXT]"},
    "FWID": {"value": "36", "desc": "Winter sports [TEXT]"},
    "FXID": {"value": "37", "desc": "Miscellaneous [TEXT]"},
    "FZID": {"value": "38", "desc": "Shipping area FM 61 (MAFOR)"},
    "CUID": {"value": "9", "desc": "CLIMAT TEMP"},
    "WAID": {"value": "39", "desc": "WARNING Airmet"},
    "WCID": {"value": "40", "desc": "WARNING Tropical cyclone (SIGMET)"},
    "WEID": {"value": "11", "desc": "WARNING Tsunami"},
    "WFID": {"value": "41", "desc": "WARNING Tornado"},
    "WGID": {"value": "42", "desc": "WARNING River flood"},
    "WHID": {"value": "43", "desc": "WARNING Coastal flood"},
    "WOID": {"value": "44", "desc": "WARNING Other"},
    "WRID": {"value": "45", "desc": "WARNING Humanitarian activity"},
    "WSID": {"value": "12", "desc": "WARNING Sigmet"},
    "WTID": {"value": "13", "desc": "WARNING Cyclone (typhoon/hurricane)"},
    "WUID": {"value": "46", "desc": "WARNING Severe Thunderstorm"},
    "WVID": {"value": "47", "desc": "WARNING Volcanic ash clouds"},
    "WWID": {"value": "48", "desc": "WARNING and Weather Summary"},
    "UAID": {"value": "49", "desc": "Aircraft Report (UAID)"},
    "UDID": {"value": "50", "desc": "Aircraft Report (UDID)"},
    "URID": {"value": "51", "desc": "Aircraft Report (URID)"},
    "UTID": {"value": "52", "desc": "Aircraft Report (UTID)"},
    "DATA": {"value": "53", "desc": "Message Balai 1 dan 5"},
    "BMAA": {"value": "54", "desc": "Message Balai 1 dan 5"},
    "BMBB": {"value": "55", "desc": "Message Balai 1 dan 5"}
}