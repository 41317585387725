<template>
  <b-tabs >
    <b-tab title="GTS MESSAGES" >
      <gts-message :roles="roles"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
// import EntrySinop from "./meteorologi/EntrySinopMain.vue";
// import Pibal from "./meteorologi/PibalMain.vue";
// import Metar from "./meteorologi/MetarMain.vue";
import GtsMessage from "./GtsMessages.vue"
import helper from "@/api/helper";

export default {
  components: {
    BTabs,
    BTab,
    "gts-message": GtsMessage,
  },

  data() {
    return {
      roles: null
    }
  },

  mounted() {
    // if (!this.$route.params.tab) {
    //   this.$router.replace({ params: { tab: 'sinoptik'} });
    // }
    this.listRoles()
  },

  methods: {
    listRoles() {
			let userLogin =  JSON.parse(localStorage.getItem('userLogin'))
			let role = userLogin.role
			let roles = {
				isRoleAdd: false,
				isRoleEdit: false,
				isRoleView: false,
       	 		isRoleDelete: false
			}
			if(role != "admin") {
				let myRoles = helper.getMenuRoles(userLogin.listperm)
				// console.log('myRoles', myRoles)

				if (myRoles.hasOwnProperty("MegaEntry")) {
					if (myRoles.MegaEntry.includes("Add")) {
						roles['isRoleAdd'] = true
					}
					if (myRoles.MegaEntry.includes("Modify")) {
						roles['isRoleEdit'] = true
					}
					if (myRoles.MegaEntry.includes("View")) {
						roles['isRoleView'] = true
					}
					if (myRoles.MegaEntry.includes("Delete")) {
						roles['isRoleDelete'] = true
					}
				}
			}
			else {
				roles['isRoleAdd'] = true
				roles['isRoleEdit'] = true
				roles['isRoleView'] = true
				roles['isRoleDelete'] = true
			}
			// console.log('roles', roles)
			this.roles = roles
		},
  }
};
</script>
